import React, { useState,useEffect } from 'react'
import './qr.css'
import { Html5QrcodeScanner } from "html5-qrcode"
//import { useApproveTicketMutation } from '../../generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
// import Navbar from '../components/header.tsx';
// import { assertValidExecutionArguments } from 'graphql/execution/execute';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

axios.defaults.withCredentials = true;

const QRScanner = () => {

    let html5QrcodeScanner;


    const [approveLoading, setApproveLoading] = useState(false);
    const [desc, setDisc] = useState('');
    const [name, setName] = useState('');
    const [uniqueID, setUniqueID] = useState('');
    const [fetchedEmail, setFetchedEmail] = useState('');

    const graphqlEndpoint = 'https://test.saarang.org/api/';
    // const graphqlEndpoint = 'http://localhost:5000/graphql';

  
    // Function to fetch user email (GetMe query)
    const fetchUserEmail = async () => {
      const query = `
        query GetMe {
          getMe {
            email
          }
        }
      `;
  
      try {
        const response = await axios.post(graphqlEndpoint, {
          query,
        });
  
        const data = response.data.data;
  
        if (data && data.getMe) {
          setFetchedEmail(data.getMe.email);
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.log(error.response?.data?.errors?.[0]?.message || 'An error occurred while fetching user data');
      }
    };
  
    useEffect(() => {
      // Fetch user email when the component loads
      fetchUserEmail();
    }, []);



    function onScanSuccess(decodedText, decodedResult) {

        let scanner = document.getElementById('reader');
        if (scanner) {
            html5QrcodeScanner.clear();
            scanner.innerHTML = '';
            console.log(decodedText);
            setApproveLoading(true);
            axios({
                method: 'post',
                url: "https://test.saarang.org/api/",
                // url: "http://localhost:5000/graphql/",
                data: {
                    query: `
                mutation ApproveTicket($ticketId: String!) {
                    approveTicket(ticket_id: $ticketId) {
                      ticket_description
                      name
                      ticket_no
                    }
                  }
                `,
                    variables: {
                        "ticketId": decodedText
                    }
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data.errors) {
                    alert(res.data.errors[0].message)
                    setName('');
                    setDisc('');
                    setUniqueID('');
                } else {
                    setDisc(res.data.data.approveTicket.ticket_description);
                    setName(res.data.data.approveTicket.name);
                    setUniqueID(res.data.data.approveTicket.ticket_no);

                }
                setApproveLoading(false);
            }).catch((err) => {
                console.log(err)
            })
        }

    }

    // Remove first letter of the string
    function removeFirstLetter(str) {
        return str.slice(1);
    }

    function onScanFailure(error) {
        // console.warn(`Code scan error = ${error}`);
    }
const navigate = useNavigate()
    const handleScan = () => {
        // Remove approved div 
        setDisc('');
        setName('');
        setUniqueID('');
        let approved = document.getElementsByClassName('approved');
        let error = document.getElementsByClassName('error');
        if (error.length > 0) {
            // visibility: hidden
            error[0].classList.add('hide');
        }

        if (approved.length > 0) {
            // visibility: hidden
            approved[0].classList.add('hide');
        }
        // Use only back camera
        html5QrcodeScanner = new Html5QrcodeScanner(
            "reader",
            { fps: 10, qrbox: { width: 200, height: 200 } },

            false);
        html5QrcodeScanner.render(onScanSuccess, onScanFailure);
    }
    const handleAuth = async () => {
        if(fetchedEmail!==''){
        const mutation = `
          mutation Mutation {
            logout
          }
        `;
    
        try {
          await axios.post(graphqlEndpoint, {
            query: mutation,
          });
    
          setFetchedEmail('');
          navigate('/'); // Navigate to home after logout
        } catch (error) {
          console.log(error.response?.data?.errors?.[0]?.message || 'Error during logout');
        }}else{
            navigate('/')
        }
      };
    
    return (

        <div className='qr-scanner'>
<div className="navbar">
            <div className="login-button" onClick={handleAuth}>{fetchedEmail===""?`Login`:`Logout`}</div>
            <div className="email">{fetchedEmail}</div>
            </div>
            <div style={{ 'minHeight': '30px' }}></div>
            <div id="reader" style={{ 'width': '80%', 'margin': 'auto' }} ></div>

            <div className='approve-result'>

                {
                    approveLoading ? <div className='loading'>Loading...</div> : <>
                        <div>Name : {name}</div>
                        <div>Description : {desc}</div>
                        <div>ID: {uniqueID}</div>
                    </>
                }

            </div>
            <div className='scan'>
                <button onClick={handleScan}>Scan</button>
            </div>
        </div>
    )
}

export default QRScanner