import logo from './logo.svg';
import React, { useState,useEffect } from 'react'
import axios from 'axios';

import './App.css';
import QRScanner from './pages/scanner';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import AuthPage from './pages/auth';
import AddQR from './pages/generator';




function App() {

  const [fetchedEmail, setFetchedEmail] = useState('');

    const graphqlEndpoint = 'https://test.saarang.org/api/';
    // const graphqlEndpoint = 'http://localhost:5000/graphql';

  
    // Function to fetch user email (GetMe query)
    const fetchUserEmail = async () => {
      const query = `
        query GetMe {
          getMe {
            email
          }
        }
      `;
  
      try {
        const response = await axios.post(graphqlEndpoint, {
          query,
        });
  
        const data = response.data.data;
  
        if (data && data.getMe) {
          setFetchedEmail(data.getMe.email);
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.log(error.response?.data?.errors?.[0]?.message || 'An error occurred while fetching user data');
      }
    };
  
    useEffect(() => {
      // Fetch user email when the component loads
      fetchUserEmail();
    }, []);
  return (
    <div className="App">
        <BrowserRouter>
        <Routes>
        <Route path="/scanner" element={<QRScanner />} />
        <Route path="/" element={<AuthPage />} />
       {fetchedEmail=='devops@saarang.org'? <Route path="/oombugai" element={<AddQR />} />:""}




          </Routes>
          </BrowserRouter>
    </div>
  );
}

export default App;
