import React from 'react';
import './generator.css';
import axiosInstance from '../config/axiosConfig';


const AddQR = () => {

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [position, setPosition] = React.useState('');
  const [gate, setGate] = React.useState('');
  const [night, setNight] = React.useState('');
  const [quantity, setQuantity] = React.useState('');

  const [regError, setRegError] = React.useState("");
  const [regSuccess, setRegSuccess] = React.useState("");


  function AddQRhandler(e) {
    e.preventDefault();
    async function addQR() {
      const response = await axiosInstance.post('', {
        query: `
            mutation directQr($pass: String!, $quantity: Float!, $ticketId: String!, $name: String!, $email: String!, $programName: String!, $passType: String!, $gate: String!) {
                directQr(pass: $pass, quantity: $quantity, ticket_id: $ticketId, name: $name, email: $email, program_name: $programName, pass_type: $passType, Gate: $gate)
              }
            `,
        variables: {
          "pass": "Zu&0Ik77*9e7wH^J",
          "quantity": parseInt(quantity),
          "ticketId": "69",
          "name": name,
          "email": email,
          "programName": night,
          "passType": position,
          "gate": gate
        }
      })
      if (response.data.errors) {
        setRegError("Fuck off");
      } else {
        setRegSuccess("QR Added Successfully");
        setName('');
        setEmail('');
        setPosition('');
        setGate('');
        setNight('');
        setQuantity('');
        window.location.reload()
      }
    }

    addQR();
  }


  return (
    <div className='add-page'>
      <h1 className='title'>Typo Bro !!!</h1>

      <div className="input_box">
        <div id="rectangle" className="d-flex justify-content-cmargin-form">
          <form className="m-3 p-2 flex-fill signUpForm" onSubmit={AddQRhandler}>
            <div className="input-box">
              <input type="text" className="form-styling" id="name" placeholder="Name" onChange={(e) => setName(e.target.value)} />
              <br></br>
              <input type="text" className="form-styling" id="email" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
              <br></br>
              <select
                className="form-styling"
                id="position"
                onChange={(e) => setPosition(e.target.value)}
                defaultValue="" // Ensures the placeholder-like behavior
              >
                <option value="" disabled>
                  Select Position
                </option>
                <option value="Gallery">Gallery</option>
                {/* <option value="Bowl">Bowl</option> */}
                {/* <option value="Fanpass">Fanpass</option> */}
                <option value="Gallery Plus">Gallery Plus</option>
                <option value="Gold(Standing)">Gold(Standing)</option>
                <option value="Gold+(sitting)">Gold+(sitting)</option>
                <option value="Platinum(Sitting)">Platinum(Sitting)</option>
              </select>
              <br></br>
              <select
                className="form-styling"
                id="gate"
                onChange={(e) => setGate(e.target.value)}
                defaultValue="" // Ensures the placeholder-like behavior
              >
                <option value="" disabled>
                  Select Gate
                </option>
                <option value="Student Gate">Student Gate</option>
                <option value="Garden Gate">Garden Gate</option>
                <option value="Family Gate">Family Gate</option>
                <option value="Main Gate">Main Gate</option>

              </select>
              <br></br>
              <select
                className="form-styling"
                id="night"
                onChange={(e) => setNight(e.target.value)}
                defaultValue=""
              >
                <option value="" disabled>
                  Select Night
                </option>
                <option value="Pop">Pop</option>
                <option value="EDM">EDM</option>
                <option value="Rock">Rock</option>
              </select>                            <br></br>
              <input type="text" className="form-styling" id="quantity" placeholder="Quantity" onChange={(e) => setQuantity(e.target.value)} />
            </div>
            <div className="d-flex justify-content-center">
              <button type="submit" className="button-style" style={{ width: "70%", paddingTop: "10px", paddingBottom: "10px", 'maxWidth': '150px', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                Add QR
              </button>
            </div>
          </form>
        </div>
      </div>
      {regError !== "" ? <p className="text-danger redir_log down">{regError}</p> : null}
      {regSuccess !== "" ? <p className="text-success redir_log down">{regSuccess}</p> : null}
    </div>
  );
};


export default AddQR;