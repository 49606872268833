import React, { useState } from 'react';
import axios from 'axios';
import './auth.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState(null);
  

  const graphqlEndpoint = 'https://test.saarang.org/api/';
  // const graphqlEndpoint = 'http://localhost:5000/graphql/';

const navigate = useNavigate()
  const toggleMode = () => {
    setIsLogin(!isLogin);
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
   

    const mutation = isLogin
      ? `
        mutation Login($password: String!, $email: String!) {
          login(password: $password, email: $email) {
            id
            email
          }
        }
      `
      : `
        mutation Register($password: String!, $email: String!) {
          register(password: $password, email: $email) {
            id
            email
          }
        }
      `;

    try {
      const response = await axios.post(graphqlEndpoint, {
        query: mutation,
        variables: { email, password },
      });

      const data = response.data.data;

      if (data && data.login) {
        setUser(data.login);
        navigate('/scanner')
      } else if (data && data.register) {
        setUser(data.register);
      } else {
        throw new Error('Unexpected response from server');
      }

      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.response?.data?.errors?.[0]?.message || 'An error occurred');
    }
  };

  return (
    <div className="auth-page-container">
      <h1 className="auth-page-header">{isLogin ? 'Login' : 'Register'}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            className="auth-page-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            className="auth-page-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="auth-page-button">
          {isLogin ? 'Login' : 'Register'}
        </button>
      </form>

      <p className="auth-page-toggle-text">
        {isLogin ? "Don't have an account? " : 'Already have an account? '}
        <span className="auth-page-toggle-link" onClick={toggleMode}>
          {isLogin ? 'Register' : 'Login'}
        </span>
      </p>

      {user && (
        <div className="auth-page-success">
          <h2>Welcome, {user.email}!</h2>
          <p>Your user ID is: {user.id}</p>
        </div>
      )}

      {errorMessage && <p className="auth-page-error">{errorMessage}</p>}
    </div>
  );
};

export default AuthPage;
