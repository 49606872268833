import React from "react";
import axios from "axios";

var api_url = 'http://localhost:5000/graphql'
var timeout = 4000
// api_url = 'https://test.saarang.org/api'
// if(process.env.REACT_APP_ENV === 'produc'){
    api_url = 'https://test.saarang.org/api/'
//     timeout = 100000
// }

console.log(process.env.REACT_APP_ENV)
console.log(api_url)

const axiosInstance = axios.create({
    baseURL: api_url,
    timeout: timeout,
    withCredentials: true,
});



export default axiosInstance ; 